<template>
  <div>
    <s-dialog to="stds-dialog-image-editor" size="md" :open="true">
      <s-dialog-overlay />
      <s-dialog-panel class="!w-[100rem] !h-[72rem]">
        <st-dialog-header class="sm:!pb-16" @clickClose="closeDialog()">
          <image-editor-header
            v-model:guide="showGuideLine"
            :size="props.size"
            :hideLogo="hideLogo"
            :name="props.dialogName"
            :isGuideShown="props.isGuideShown"
          />
        </st-dialog-header>
        <s-dialog-content class="!mx-0 !px-0 relative overflow-hidden">
          <image-cropper
            ref="cropImage"
            :imgUrl="cropImageBackground"
            :fillColor="fillColor"
            :autoCropWidth="autoCropWidth"
            :autoCropHeight="autoCropHeight"
            :stencilSize="stencilSize"
            class="overflow-hidden"
            :sizeRestrictionsAlgorithm="sizeRestrictionsAlgorithm"
            :defaultSize="defaultSize"
            @image="(image: ImageCopper) => (previewImage = image)"
            @dataUrl="(data: string) => {
              dataUrl = data;
            }"
            @click="onSelectObject(IMAGE_TYPE.BACKGROUND)"
          />
          <draggable-resizable-vue
            v-if="logoImage"
            v-model:x="logoDom.x"
            v-model:y="logoDom.y"
            v-model:w="logoDom.width"
            v-model:h="logoDom.height"
            :lockAspectRatio="true"
            :z="10"
          >
            <img
              ref="logoImgRef"
              :src="logoImage"
              class="w-full h-full transition-transform duration-300"
              :style="{ opacity: `${Number(opacityLogo) / 100}`, transform: currentLogoTranForm }"
              @click="onSelectObject(IMAGE_TYPE.LOGO)"
            />
          </draggable-resizable-vue>
          <safe-area-box
            v-if="showGuideLine"
            :hideLogo="hideLogo"
            :safeArea="safeAreaBoxWithDefault"
            :safeAreaLogo="safeAreaLogo"
            :guideType="props.guideType"
          />
          <s-toast-area
            v-bind="$props"
            class="absolute z-[9999] bottom-[0.4rem] w-auto [&>*]:rounded-full [&>*>p]:text-base"
          />
        </s-dialog-content>

        <image-editor-footer
          v-model:opacityLogo="opacityLogo"
          v-model:pickColor="fillColor"
          :isBg="!!backgroundImage"
          :isLogo="!!logoImage"
          :hideLogo="hideLogo || logoImage !== ''"
          :hideUploadToStorage="hideUploadToStorage"
          :disableOpacity="disableOpacity"
          @logoImg="handleUpdateLogoImage"
          @logoDimension="onLogoImageDimension"
          @onMerge="onMergeImages"
          @onUploadStorage="onUploadStorage"
          @onRotate="onRotateImage"
          @onFlip="onFlipImage"
          @onDeleteLogo="onDeleteLogo"
        />
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target name="stds-dialog-image-editor" />
  </div>
</template>

<script setup lang="ts">
import { DraggableResizableVue } from 'draggable-resizable-vue3';
import mergeImages from 'merge-images';
import { storeToRefs } from 'pinia';
import { axios } from 'seed-core';
import { computed, getCurrentInstance, ref, toRefs } from 'vue';
import type { SizeRestrictions } from 'vue-advanced-cropper';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import ImageCropper from '@/components/common/image-cropper.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import ImageEditorFooter from '@/components/image-editor/footer.vue';
import ImageEditorHeader from '@/components/image-editor/header.vue';
import SafeAreaBox from '@/components/image-editor/safe-area-box.vue';
import { showAlert } from '@/composables/useDialog';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import {
  ICO_MIME_TYPE,
  ICO_MS_MIME_TYPE,
  IMAGE_FILE_EXTENSION,
  WEBP_MIME_TYPE
} from '@/constants/file.const';
import type { PRODUCT_PAGE_IMAGE_EDITOR_GUIDE_TYPE } from '@/enums/product-page.enum';
import { useStorageUploadStore } from '@/stores/storage-upload.store';
import type { ImageCopper, ResultMergeImage } from '@/types/common/file.type';
import type { OrNull } from '@/types/core.type';
import type { ImageDimension, ImageDimensionWithPosition } from '@/types/image/image.type';
import { drawBase64Image } from '@/utils/file.util';

const route = useRoute();
const { t } = useI18n();

enum IMAGE_TYPE {
  BACKGROUND = 'background',
  LOGO = 'logo'
}

const props = defineProps<{
  type: string;
  background?: string;
  size: string;
  hideLogo?: boolean;
  autoCropWidth: number;
  autoCropHeight: number;
  disableOpacity?: boolean;
  stencilSize?: ImageDimension;
  safeArea?: ImageDimension;
  fileName?: string;
  dialogName?: string;
  isGuideShown?: boolean;
  isZoomLimit?: boolean;
  guideType?: PRODUCT_PAGE_IMAGE_EDITOR_GUIDE_TYPE;
  safeAreaLogo?: ImageDimension;
  hideUploadToStorage?: boolean;
}>();

const emit = defineEmits<{
  close: [result?: ResultMergeImage];
}>();

const IMAGE_TYPES_NOT_CONVERT_WEBP: Record<string, Record<string, string>> = {
  [ICO_MS_MIME_TYPE]: {
    mimeType: ICO_MS_MIME_TYPE,
    fileExt: IMAGE_FILE_EXTENSION.ICO
  },
  [ICO_MIME_TYPE]: {
    mimeType: ICO_MIME_TYPE,
    fileExt: IMAGE_FILE_EXTENSION.ICO
  }
};

const projectId = route.params.projectId as string;
const dialogWidth = 1000;
const dialogHeight = 564;

const proxy: any = getCurrentInstance()?.proxy;

const storageUploadStore = useStorageUploadStore();

const { cancelTokenSource } = storeToRefs(storageUploadStore);

const showToastSuccessUploaded = () => {
  proxy?.$SToast({
    text: t(
      'studio.prj_prod.this_prod.edit_gamepreview_import_pop_image_editor_save_storage_btn_toast'
    ),
    duration: 1200
  });
};

const disabledTeleport = ref<boolean>(true);
const cropImage = ref<InstanceType<typeof ImageCropper>>();

// guide
const showGuideLine = ref<boolean>(false);

// background
const backgroundImage = ref<string>('');
const cropImageBackground = ref<string>('');
const backgroundFileName = ref<string>(props.fileName ?? '');

const isHorizontalRotate = ref<boolean>(true);
const fillColor = ref<string>('None');
const previewImage = ref<ImageCopper>();

const stencilBox = ref();
const logoImgRef = ref<HTMLElement>();

const { autoCropWidth, autoCropHeight, background } = toRefs(props);

const dataUrl = ref<string>('');

const safeAreaBoxWithDefault = computed(() => {
  if (props.safeArea && props.safeArea.width && props.safeArea.height) {
    return props.safeArea;
  }

  return {
    width: (autoCropWidth.value * 50) / 100,
    height: (autoCropHeight.value * 50) / 100
  };
});

// logo
const logoImage = ref<string>('');
const logoDom = ref<ImageDimensionWithPosition>({
  x: 0,
  y: 0,
  width: 100,
  height: 100
});

const logoDimension = ref<OrNull<ImageDimension>>();

const opacityLogo = ref<string>('100');
let currentSelectedImg = IMAGE_TYPE.BACKGROUND;
let currentLogoAngle = 0;
let currentLogoScaleX = 1;
const defaultLogoTransform = 'scaleX(1) rotate(0deg)';
const currentLogoTranForm = ref<string>(defaultLogoTransform);

const setElementActivity = (element: HTMLElement, isActive: boolean) => {
  if (element) {
    element.style.setProperty('opacity', isActive ? '1' : '0.5');
  }
};

const setLogoImageActivation = (isActive: boolean) => {
  const logoImageContainer = document.querySelector(
    '.drv-draggable.drv-resizable.drv'
  ) as HTMLElement;
  setElementActivity(logoImageContainer, isActive);
};

const setBackgroundImageActivation = (isActive: boolean) => {
  const backgroundImage = document.querySelector('.vue-preview__image') as HTMLElement;
  setElementActivity(backgroundImage, isActive);
};
const onSelectObject = (type: IMAGE_TYPE) => {
  currentSelectedImg = type;
  if (type === IMAGE_TYPE.BACKGROUND) {
    setLogoImageActivation(false);
    setBackgroundImageActivation(true);
  } else {
    setLogoImageActivation(true);
    setBackgroundImageActivation(false);
  }
};
const defaultSize = ({ imageSize }: any) => {
  const stencilAspectRatio =
    props.stencilSize?.width && props.stencilSize?.height
      ? props.stencilSize.width / props.stencilSize.height
      : autoCropWidth.value / autoCropHeight.value;
  const size =
    stencilAspectRatio >= 1
      ? { width: imageSize.width, height: imageSize.width }
      : { width: imageSize.height, height: imageSize.height };
  return size;
};

const sizeRestrictionsAlgorithm = ({
  minWidth,
  minHeight,
  maxWidth,
  maxHeight
}: SizeRestrictions) => {
  if (props.isZoomLimit && props.stencilSize) {
    return {
      maxWidth: props.stencilSize.width * 10,
      minWidth: props.stencilSize.width * 0.5
    };
  } else {
    return { maxWidth, maxHeight, minWidth, minHeight };
  }
};

const handleUpdateBackgroundImage = async (image: string) => {
  if (!image) {
    return;
  }

  backgroundImage.value = image;
  cropImageBackground.value = image;
};

const handleUpdateLogoImage = async (image: string) => {
  logoImage.value = image;
  stencilBox.value = document.querySelector('.vue-rectangle-stencil');
  onSelectObject(IMAGE_TYPE.LOGO);
  setLogoImageActivation(true);
  setBackgroundImageActivation(false);
};

const setLogoTransformValue = (transform: string) => {
  currentLogoTranForm.value = transform;
};

const setLogoScaleX = (scaleX: number) => {
  currentLogoScaleX = scaleX;
};

const setLogoRotateAngle = (angle: number) => {
  currentLogoAngle = angle;
};

const onLogoImageDimension = (dimension: OrNull<ImageDimension>) => {
  const cropBox = document.querySelector('.vue-rectangle-stencil');

  if (!cropBox) {
    return;
  }

  const clientWidth = cropBox.clientWidth;
  const clientHeight = cropBox.clientHeight;

  if (dimension) {
    const biggerSize = dimension.width > dimension.height ? dimension.width : dimension.height;
    const smallerDomSize = clientWidth < clientHeight ? clientWidth : clientHeight;

    let newLogo;
    if (biggerSize > smallerDomSize) {
      const newWidth = (dimension.width * smallerDomSize) / dimension.height;

      newLogo = {
        width: newWidth,
        height: smallerDomSize
      };
    }

    logoDom.value.width = newLogo?.width || dimension.width;
    logoDom.value.height = newLogo?.height || dimension.height;
    logoDom.value.x = Math.round((dialogWidth - logoDom.value.width) / 2);
    logoDom.value.y = -Math.round((dialogHeight + logoDom.value.width) / 2);

    logoDimension.value = dimension;
  }
};
const rotateLogoImage = () => {
  if (!logoImgRef.value) {
    return;
  }
  let transform = currentLogoTranForm.value;
  const newAngle = currentLogoAngle + 90 * currentLogoScaleX;
  transform = transform.replace(`rotate(${currentLogoAngle}deg)`, `rotate(${newAngle}deg)`);
  setLogoTransformValue(transform);
  setLogoRotateAngle(newAngle);
};
const flipLogoImage = () => {
  if (!logoImgRef.value) {
    return;
  }
  let transform = currentLogoTranForm.value;
  const newScaleX = currentLogoScaleX * -1;
  transform = transform.replace(`scaleX(${currentLogoScaleX})`, `scaleX(${newScaleX})`);
  setLogoTransformValue(transform);
  setLogoScaleX(newScaleX);
};
const onFlipImage = async () => {
  if (currentSelectedImg === IMAGE_TYPE.BACKGROUND && cropImage.value) {
    cropImage.value.flipImage(isHorizontalRotate.value, !isHorizontalRotate.value);
  } else if (currentSelectedImg === 'logo' && logoImgRef.value) {
    flipLogoImage();
  }
};

const onRotateImage = () => {
  if (currentSelectedImg === IMAGE_TYPE.BACKGROUND && cropImage.value) {
    isHorizontalRotate.value = !isHorizontalRotate.value;
    cropImage.value.rotateImage(90);
  } else if (currentSelectedImg === 'logo' && logoImgRef.value) {
    rotateLogoImage();
  }
};

const resetLogoData = () => {
  setLogoTransformValue(defaultLogoTransform);
  onSelectObject(IMAGE_TYPE.BACKGROUND);
  setLogoScaleX(1);
  setLogoRotateAngle(0);
};

const onDeleteLogo = () => {
  resetLogoData();
  setBackgroundImageActivation(true);
};

const domLogoPosition = computed(() => {
  return {
    x: logoDom.value.x - (dialogWidth - stencilBox.value?.clientWidth) / 2,
    y: logoDom.value.y + dialogHeight - (dialogHeight - stencilBox.value?.clientHeight) / 2
  };
});

const getMergeImagesB64 = async (): Promise<string | undefined> => {
  let fillColorValue = 'transparent';
  if (fillColor.value) {
    fillColorValue = fillColor.value === 'None' ? 'transparent' : fillColor.value;
  }

  const base64BackgroundImgWithBackgroundColor = await drawBase64Image({
    base64String: dataUrl.value,
    width: autoCropWidth.value,
    height: autoCropHeight.value,
    fillColor: fillColorValue
  });

  if (logoImage.value === '' || props.hideLogo) {
    return base64BackgroundImgWithBackgroundColor;
  }

  if (!props.stencilSize) {
    return;
  }

  const logoReal = {
    x: Math.round((domLogoPosition.value.x * autoCropWidth.value) / props.stencilSize.width),
    y: Math.round((domLogoPosition.value.y * autoCropHeight.value) / props.stencilSize.height),
    width: Math.round((logoDom.value.width * autoCropWidth.value) / props.stencilSize.width),
    height: Math.round((logoDom.value.height * autoCropHeight.value) / props.stencilSize.height)
  };

  const base64LogoWithOpacity = await drawBase64Image({
    base64String: logoImage.value,
    width: logoReal.width,
    height: logoReal.height,
    opacity: Number(opacityLogo.value) / 100,
    isFlip: currentLogoScaleX === -1,
    rotate: currentLogoAngle
  });
  const mergeOption: mergeImages.Options = {
    format: props.type
  };
  if (autoCropHeight.value > 0 && autoCropWidth.value > 0) {
    mergeOption.width = autoCropWidth.value;
    mergeOption.height = autoCropHeight.value;
  }
  return await mergeImages(
    [
      {
        src: base64BackgroundImgWithBackgroundColor,
        x: 0,
        y: 0
      },
      {
        src: base64LogoWithOpacity,
        x: logoReal.x,
        y: logoReal.y
      }
    ],
    mergeOption
  );
};

const onMergeImages = async () => {
  const b64 = await getMergeImagesB64();
  if (!b64) {
    return;
  }
  emit('close', {
    base64: b64,
    fileName: backgroundFileName.value
  });
};

const saveFiles = async (files: any[]) => {
  const { newFileCount, failValues } = await storageUploadStore.uploadFiles(files, { projectId });
  if (newFileCount && !failValues.length) {
    showToastSuccessUploaded();
  }
  if (failValues?.length) {
    switch (failValues[0]?.statusCode) {
      case STATUS_CODE.FILE_SIZE_ZERO:
        await showAlert({
          content: t('studio.prj_prod_mngmt.storage_upload_method1.upload_damaged_msg')
        });
        break;
      case STATUS_CODE.PROJECT_CAPACITY_EXCEEDED:
        await showAlert({
          content: t('studio.prj_prod_mngmt.storage_upload_method1.storage_exceed_msg ')
        });
        break;
      default:
        await showAlert({
          severity: 'info',
          content: t(COMMON_ERROR_MESSAGE_KEY)
        });
        break;
    }
  }
  cancelTokenSource.value = axios.CancelToken.source();
};

const getImageUploadInfo = (mimeType: string): Record<string, string> => {
  const imgNotConvertWebpInfo = IMAGE_TYPES_NOT_CONVERT_WEBP[mimeType];
  if (imgNotConvertWebpInfo) {
    return imgNotConvertWebpInfo;
  }
  return {
    mimeType: WEBP_MIME_TYPE,
    fileExt: IMAGE_FILE_EXTENSION.WEBP
  };
};

const onUploadStorage = async () => {
  const b64 = await getMergeImagesB64();
  if (!b64) {
    return;
  }
  const { fileExt, mimeType } = getImageUploadInfo(props.type);
  const res: Response = await fetch(b64);
  const blob: Blob = await res.blob();
  const file = new File([blob], `${backgroundFileName.value}_${Date.now()}${fileExt}`, {
    type: mimeType
  });
  await saveFiles([file]);
};

const closeDialog = () => {
  disabledTeleport.value = false;
  emit('close');
};

const init = async () => {
  if (background.value) {
    await handleUpdateBackgroundImage(background.value);
  }
};

init();
</script>

<style lang="scss">
.drv-handles .drv-handle {
  border: 0.5px solid #00ffee !important;
}
.drv {
  border: 3px solid #00ffee !important;
}
</style>
