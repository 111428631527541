import { ref } from 'vue';

export interface EyeDropperOpenOptions {
  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal
   */
  signal?: AbortSignal;
}
export interface EyeDropper {
  // eslint-disable-next-line @typescript-eslint/no-misused-new
  new (): EyeDropper;
  open: (options?: EyeDropperOpenOptions) => Promise<{
    sRGBHex: string;
  }>;
  [Symbol.toStringTag]: 'EyeDropper';
}
export interface UseEyeDropperOptions {
  /**
   * Initial sRGBHex.
   *
   * @default ''
   */
  initialValue?: string;
}
/**
 * Reactive [EyeDropper API](https://developer.mozilla.org/en-US/docs/Web/API/EyeDropper_API)
 *
 * @see https://vueuse.org/useEyeDropper
 */
export function useEyeDropper(options: UseEyeDropperOptions = {}) {
  const { initialValue = '' } = options;
  const sRGBHex = ref(initialValue);

  const isSupported = 'EyeDropper' in window;

  async function open() {
    if (isSupported) {
      const abortController = new AbortController();

      const eyeDropper: EyeDropper = new (window as any).EyeDropper();
      const result = await eyeDropper.open({ signal: abortController.signal });
      sRGBHex.value = result.sRGBHex;
      return result;
    }
  }

  return { sRGBHex, open, isSupported };
}

export type UseEyeDropperReturn = ReturnType<typeof useEyeDropper>;
