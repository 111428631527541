<template>
  <s-dialog to="stds-dialog-before-selecting" size="sm" :open="true">
    <s-dialog-overlay />
    <s-dialog-panel class="relative !w-[56rem] rounded-[2.8rem] overflow-visible">
      <s-dialog-content class="px-32 pt-24 m-0 overflow-visible">
        <s-dialog-content-body class="overflow-visible mt-0">
          <div class="flex flex-col gap-8 min-h-[20.4rem] items-center justify-center">
            <s-text
              as="h3"
              role="title7"
              class="text-on-surface-elevation-1 text-center !font-bold"
            >
              {{ $t('studio.group.collection.storage_select_popup_guide') }}
            </s-text>
            <div class="flex flex-col gap-8 w-full py-24">
              <div class="w-full relative">
                <s-dropdown
                  :value="selectedProject"
                  variant="line"
                  size="lg"
                  :distance="4"
                  :offset="[0]"
                  placement="bottom"
                  :closeOnClick="true"
                  :interactive="true"
                  class="overlay-tippy w-full"
                  @update:modelValue="handleSelectProject"
                >
                  <s-dropdown-button :class="{ 'text-placeholder': !selectedProject }">
                    {{
                      selectedProject
                        ? `${selectedProject.projectName}`
                        : $t('studio.group.collection.storage_select_prj_dropbox')
                    }}
                  </s-dropdown-button>
                  <template #dropdownItems>
                    <div class="py-2 px-12">
                      <input-text
                        v-model="searchText"
                        searchable
                        size="sm"
                        :placeholder="$t('studio.common.search_place_holder')"
                        @search="handleSearchProject(searchText)"
                        @clear="handleSearchProject"
                      />
                    </div>
                    <template v-if="hasProjects">
                      <s-dropdown-item
                        v-for="item in projects"
                        :key="item.projectId"
                        :value="{ ...item }"
                      >
                        {{ item.projectName }}
                      </s-dropdown-item>
                    </template>
                    <template v-else>
                      <div class="flex justify-center items-center min-h-[10.3rem]">
                        <s-text as="p" role="text4" class="text-on-surface-elevation-2">
                          {{ $t('studio.group.collection.storage_search_no_result') }}
                        </s-text>
                      </div>
                    </template>
                  </template>
                </s-dropdown>
              </div>
              <st-error-message
                v-if="errorMessage && JSON.parse(errorMessage).message"
                name="project"
                class="w-full"
                :errorMessage="errorMessage"
              />
            </div>
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
      <s-dialog-footer class="pt-0">
        <s-button class="!w-128" variant="outline" @click="$emit('close')">
          {{ $t('studio.common.popup_case_cancel_btn') }}
        </s-button>
        <s-button class="!w-128" @click="handleSubmitProject">
          {{ $t('studio.group.collection.storage_select_prj_cf_btn') }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>
  <s-portal-target name="stds-dialog-before-selecting" />
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import { fetchProjectListApi } from '@/apis/project-product.api';
import StErrorMessage from '@/components/common/st-error-message.vue';
import InputText from '@/components/validation/input-text.vue';
import { SORT_DIRECTION } from '@/constants/common.const';
import { PROJECT_PAGE_SIZE } from '@/constants/project-product.const';
import { STORAGE_UPLOAD_SORT_OPTIONS } from '@/constants/storage-upload.const';
import { PROJECT_PRODUCT_SEARCH_TYPE } from '@/enums/product-page.enum';
import { RuleNames } from '@/enums/validation.enum';
import { useUserStore } from '@/stores/user.store';
import type { FetchProjectRequest } from '@/types/project-product/project-product-request.type';
import type { ProjectProductResponse } from '@/types/project-product/project-product-response.type';
import { generateErrorMsg } from '@/utils/validation.util';

const emit = defineEmits<{
  close: [v?: string];
}>();

const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);

const projects = ref<ProjectProductResponse[]>();
const selectedProject = ref<ProjectProductResponse>();
const searchText = ref<string>('');
const errorMessage = ref();

const hasProjects = computed(() => projects.value && projects.value.length > 0);

const handleSelectProject = (value: ProjectProductResponse) => {
  errorMessage.value = '';
  selectedProject.value = value;
};

const handleSubmitProject = () => {
  if (!selectedProject.value) {
    errorMessage.value = generateErrorMsg(
      'studio.group.collection.storage_select_prj_required_msg',
      RuleNames.REQUIRED
    );
    return;
  }
  emit('close', selectedProject.value.projectId);
};

const handleSearchProject = async (searchText?: string) => {
  const request: FetchProjectRequest = {
    groupId: selectedGroupId.value,
    page: 1,
    size: PROJECT_PAGE_SIZE,
    direction: SORT_DIRECTION.DESC,
    sort: STORAGE_UPLOAD_SORT_OPTIONS.CREATED_AT,
    q: searchText,
    searchType: PROJECT_PRODUCT_SEARCH_TYPE.PROJECT_NAME
  };

  const result = await fetchProjectListApi(request);
  if (!result) {
    return;
  }

  projects.value = result.contents;
};

const init = async () => {
  handleSearchProject();
};

init();
</script>
