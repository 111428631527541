<template>
  <div
    class="flex min-h-72 flex-col justify-center gap-8 rounded-xl border-1 border-solid border-border bg-[var(--stds-glob-color-gray20)] px-16 py-12 mt-8"
    :class="{ 'mt-12 select-none focus-visible:none': inList }"
  >
    <div v-if="inList" class="flex w-full items-center gap-8">
      <s-icon
        size="3xl"
        icon="ic-v2-navigation-menu-line"
        class="shrink-0 cursor-grab text-on-surface-elevation-3 active:cursor-grabbing"
        :class="{ 'pointer-events-none': isDisabled }"
      />
      <p class="flex-1 text-sm leading-md text-on-surface-elevation-3">
        {{ $t(description) }}
      </p>
      <button type="button" :disabled="isDisabled" @click="onRemoveFromListFile">
        <s-icon
          size="xl"
          icon="ic-v2-control-close-line"
          class="shrink-0"
          :class="isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'"
        />
      </button>
    </div>
    <input-file
      ref="inputTextFileRef"
      class="flex items-center gap-12"
      :name="`${name}.image`"
      :rules="imageValidationRules"
      :showErr="false"
      multiple
      :allowExtensions="allowExtensions"
      @fileSelect="handleFileSelect"
    >
      <template #default="{ triggerUpload }">
        <template v-if="!resultImage?.linkCdn">
          <span
            class="studio-upload-image-ready inline-flex h-48 w-48 shrink-0 items-center justify-center overflow-hidden rounded-lg bg-disabled-variant-3"
          >
            <s-icon
              size="xl"
              icon="ic-v2-community-attach-image-line"
              :class="isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-1'"
            />
          </span>
          <div class="flex flex-1 items-center gap-12">
            <div class="flex flex-1 flex-col justify-center gap-2">
              <div class="flex items-center gap-4">
                <p class="text-xs font-bold leading-xs text-on-surface-elevation-2">
                  {{ resolution || '756X426' }}{{ showPixel ? 'px' : '' }}
                </p>
                <span
                  v-if="tagValue"
                  class="flex h-[2.2rem] shrink-0 items-center rounded-full bg-[var(--stds-glob-color-gray60)] px-[.6rem] text-2xs leading-xs text-gray500"
                >
                  {{ $t(tagValue) }}
                </span>
              </div>
              <p class="text-xs leading-xs text-placeholder">
                {{
                  fileLabel ??
                    `${$t('studio.common.file_upl_guide1_1', {
                      acceptFileExtAttributeLabel: acceptFileExtAttributeLabel
                    })} | ${$t('studio.common.file_upl_guide1_2', { size: acceptFileSize })}`
                }}
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-1 items-center gap-12">
            <span
              v-if="!isImageFailed"
              class="relative h-48 w-48 shrink-0 overflow-hidden rounded-lg border-1 border-solid border-border"
              :class="{ 'after:absolute after:inset-0 after:bg-black/40': isDisabled }"
            >
              <template v-if="type === FILE_TYPE.VIDEO">
                <video
                  :key="resultImage?.linkCdn"
                  :controls="false"
                  class="h-full w-full object-cover"
                >
                  <source :src="resultImage?.linkCdn" type="video/mp4" />
                </video>
                <s-icon
                  size="4xl"
                  icon="ic-v2-media-av-play-round-fill"
                  class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-white before:relative before:z-[1] after:absolute after:inset-12 after:rounded-full after:bg-black/40 drop-shadow-[0_0_0.3rem_rgba(0,0,0,0.5)]"
                />
              </template>
              <template v-else>
                <img
                  :src="resultImage?.thumbnail ?? resultImage?.linkCdn"
                  class="h-full w-full object-cover"
                  @error="isImageFailed = true"
                />
              </template>
            </span>
            <span
              v-if="isImageFailed"
              class="studio-upload-image-ready inline-flex h-48 w-48 shrink-0 items-center justify-center overflow-hidden rounded-lg bg-black/20"
            >
              <s-icon
                size="xl"
                icon="ic-v2-community-chat-broken-image-fill"
                class="text-[#484a51]"
              />
            </span>

            <div class="flex flex-1 flex-col items-start gap-4">
              <span
                v-if="tagValue"
                class="flex h-[2.2rem] shrink-0 items-center rounded-full bg-[var(--stds-glob-color-gray60)] px-[.6rem] text-2xs leading-xs text-gray500"
              >
                {{ $t(tagValue) }}
              </span>
              <div class="flex items-center gap-4">
                <s-tooltip
                  :content="resultImage.name"
                  :duration="0"
                  :distance="0"
                  useFlip
                  flipOnUpdate
                  placement="bottom"
                  trigger="mouseenter focus"
                  :allowHTML="true"
                  :zIndex="2501"
                >
                  <template #target>
                    <safe-html
                      class="flex items-center break-all text-xs font-medium leading-xs"
                      :class="
                        isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'
                      "
                      tag="p"
                      :html="formattedFileName"
                    />
                  </template>
                </s-tooltip>
                <button
                  :disabled="isDisabled && !showMessageWhenDisabled"
                  type="button"
                  @click="onClearUploadedFile"
                >
                  <s-icon
                    size="xl"
                    icon="ic-v2-control-close-circle-fill"
                    class="shrink-0"
                    :class="
                      isDisabled && !showMessageWhenDisabled
                        ? 'text-disabled-variant-1'
                        : 'text-on-surface-elevation-4'
                    "
                  />
                </button>
              </div>
            </div>
          </div>
        </template>
        <div class="flex shrink-0 gap-[.6rem]">
          <s-tooltip
            v-if="editable"
            arrow
            :content="$t('studio.prj_prod.this_prod.edit_gamepreview_hover_msg3')"
            :duration="0"
            useFlip
            flipOnUpdate
            placement="top"
            trigger="mouseenter focus"
            :theme="'studio-tooltip'"
            :zIndex="2500"
            :offset="[0, 8]"
          >
            <template #target>
              <button
                type="button"
                :disabled="isDisabled"
                class="inline-flex h-36 w-36 items-center justify-center rounded-full"
                :class="isDisabled ? 'bg-disabled-variant-3' : 'bg-neutral-variant-2'"
                @click="openDialog(`${resultImage.linkCdn}?ts=${Date.now()}` || '')"
              >
                <s-icon
                  size="3xl"
                  icon="ic-v2-community-filter-line"
                  :class="isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'"
                />
              </button>
            </template>
          </s-tooltip>
          <s-tooltip
            arrow
            :content="$t('studio.prj_prod.this_prod.edit_gamepreview_hover_msg1')"
            :duration="0"
            useFlip
            flipOnUpdate
            placement="top"
            trigger="mouseenter focus"
            :theme="'studio-tooltip'"
            :zIndex="2500"
            :offset="[0, 8]"
          >
            <template #target>
              <button
                type="button"
                :disabled="isDisabled"
                class="inline-flex h-36 w-36 items-center justify-center rounded-full bg-neutral-variant-2"
                :class="isDisabled ? 'bg-disabled-variant-3' : 'bg-neutral-variant-2'"
                @click="triggerUpload"
              >
                <s-icon
                  size="3xl"
                  icon="ic-v2-hardware-desktop-line"
                  :class="isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'"
                />
              </button>
            </template>
          </s-tooltip>
          <s-tooltip
            arrow
            :content="$t('studio.prj_prod.this_prod.edit_gamepreview_hover_msg2')"
            :duration="0"
            useFlip
            flipOnUpdate
            placement="top"
            trigger="mouseenter focus"
            :theme="'studio-tooltip'"
            :zIndex="2500"
            :offset="[0, 8]"
          >
            <template #target>
              <button
                type="button"
                :disabled="isDisabled"
                class="inline-flex h-36 w-36 items-center justify-center rounded-full bg-neutral-variant-2"
                :class="isDisabled ? 'bg-disabled-variant-3' : 'bg-neutral-variant-2'"
                @click="onUploadFileFromStorage"
              >
                <s-icon
                  size="3xl"
                  icon="ic-v2-object-cabinet-line"
                  :class="isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'"
                />
              </button>
            </template>
          </s-tooltip>
        </div>
      </template>
    </input-file>

    <input-product-file-addtional
      v-if="type === 'video' && name.includes('exhibitionVideosAndImages')"
      :name="`${name}.movieThumbnailUrl`"
    />
    <div v-if="type === 'link'" class="flex flex-col gap-4">
      <input-text
        v-model="movieUrl"
        :name="`${name}.movieUrl`"
        :placeholder="
          $t('studio.prj_prod.this_prod.edit_gamepreview_spotlights_video_link_place_holder')
        "
        class="w-full"
        :rules="{
          required: {
            value: true,
            message: $t('studio.prj_prod.this_prod.edit_gamepreview_spotlights_add_btn_alert3'),
            showError: false
          },
          max_length: {
            value: 2000,
            showError: false,
            message: $t('studio.prj_prod.this_prod.edit_gamepreview_spotlights_add_btn_alert4')
          },
          regex: {
            regex: HTTPS_URL_REGEX,
            message: $t('studio.prj_prod.this_prod.edit_gamepreview_spotlights_add_btn_alert5'),
            showError: false
          }
        }"
        :inputClass="
          isDisabled || (resultImage && !resultImage.linkCdn) ? 'pointer-events-none' : ''
        "
        :disabled="isDisabled || (resultImage && !resultImage.linkCdn)"
        @update:modelValue="emits('updateMovieThumbnail', movieUrl)"
      />
      <s-text as="p" role="cap2" class="flex items-center text-placeholder">
        <i
          class="w-16 h-full shrink-0 flex items-center justify-center before:w-4 before:h-4 before:rounded-full before:bg-placeholder"
        ></i>
        <span class="flex-1">{{
          $t('studio.pro_pg_edit.gamepreview_spotlights_video_link_guide')
        }}</span>
      </s-text>
    </div>
  </div>
  <st-error-message
    v-if="error && isRequiredError && required && isClickedSave && JSON.parse(error).message"
    :errorMessage="error"
  />
  <st-error-message v-if="errorLink" :errorMessage="errorLink" />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldError, useFieldValue, useSetFieldError, useSetFieldValue } from 'vee-validate';
import { computed, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { uploadFileApi } from '@/apis/file.api';
import SafeHtml from '@/components/common/safe-html.vue';
import StErrorMessage from '@/components/common/st-error-message.vue';
import ImageDialog from '@/components/image-editor/image-dialog.vue';
import InputProductFileAddtional from '@/components/product-page/input-product-file-addtional.vue';
import StorageProjectSelectingDialog from '@/components/storage/storage-project-selecting-dialog.vue';
import StorageUpload from '@/components/storage/storage-upload.vue';
import InputFile from '@/components/validation/input-file.vue';
import InputText from '@/components/validation/input-text.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showDialog } from '@/composables/useDialog';
import {
  ARRAY_IMAGE_FILE_EXTENSION_WITHOUT_ICO,
  ARRAY_VIDEO_FILE_EXTENSION,
  FILE_EXTENSION,
  ICO_MS_MIME_TYPE,
  IMAGE_FILE_EXTENSION,
  IMAGE_MIMES_TYPE,
  IMAGE_TYPE,
  MAP_FILE_TYPE_TO_MINE_TYPE,
  MP4_MIME_TYPE,
  SIZE_UNIT,
  VIDEO_TYPE,
  WEBM_MIME_TYPE
} from '@/constants/file.const';
import { HTTPS_URL_REGEX } from '@/constants/regex.const';
import { MenuAuthorityIds } from '@/enums/common.enum';
import { FileRemoveType } from '@/enums/input-product-file.enum';
import { FILE_TYPE } from '@/enums/product-page.enum';
import { ErrorViewTypes, RuleNames } from '@/enums/validation.enum';
import { useAppStore } from '@/stores/app.store';
import { useGroupHomeStore } from '@/stores/group-home.store';
import { useProductPageStore } from '@/stores/product-page.store';
import { useUserStore } from '@/stores/user.store';
import type { ImageLanguageType, ResultMergeImage } from '@/types/common/file.type';
import type { StorageUploadPopupProps } from '@/types/common/popup.type';
import type { InputProductFileProps } from '@/types/product-page.type';
import type { StorageUploadFileType } from '@/types/storage-upload/storage-upload-file.type';
import type { ValidationRule } from '@/types/validation.type';
import { throwContentError } from '@/utils/api-error.util';
import {
  checkFileNameMaxLength,
  convertBase64ToFile,
  convertCdnToBase64,
  convertFileToBase64,
  getImageDimension,
  getVideoDimension
} from '@/utils/file.util';
import { validateIcoFile } from '@/utils/file-validation.util';
import { isAnimatedWebP, isCorrectResolution } from '@/utils/product-page.util';
import { convertShortFileNameWithContainerWidth } from '@/utils/string.util';
import { generateErrorMsg } from '@/utils/validation.util';

const props = withDefaults(defineProps<InputProductFileProps>(), {
  errorViewType: ErrorViewTypes.POPUP,
  inList: false,
  original: true,
  mode: 'contain',
  fileNameWidth: 350,
  expandedWidth: undefined,
  expandFileNameByRightWing: false,
  showPixel: true,
  required: false,
  showEditButton: true,
  dialogName: undefined,
  isGuideShown: false,
  isZoomLimit: false,
  guideType: undefined,
  showMessageWhenDisabled: false
});

const emits = defineEmits<{
  addFile: [v: ImageLanguageType, fileId?: number | string];
  removeFile: [fId?: number | string, removeType?: FileRemoveType];
  updateMovieThumbnail: [v?: string];
}>();

const { t } = useI18n();
const app = useApp();

const { storage } = useAppStore();
const route = useRoute();
const { projectId } = route.params;
const { productNo: collectionId } = route.query;

const productPageStore = useProductPageStore();
const { expandFileNameByEditor, isClickedSave } = storeToRefs(productPageStore);

const groupHomeStore = useGroupHomeStore();
const { projectsProductsCounts } = storeToRefs(groupHomeStore);
const { getGroupProjectsProductsCounts } = groupHomeStore;

const userStore = useUserStore();
const { selectedGroupId, selectedGroupInfo } = storeToRefs(userStore);

const appStore = useAppStore();
const { startLoading, endLoading } = appStore;

const gifTypeArray = [IMAGE_MIMES_TYPE.GIF, MP4_MIME_TYPE, WEBM_MIME_TYPE, IMAGE_TYPE.GIF];

const isImageFailed = ref<boolean>(false);
const fileTypeRef = ref<string>('');

const inputTextFileRef = ref<InstanceType<typeof InputFile>>();
const selectedProjectId = ref<string>();

const isCollection = computed(() => {
  return collectionId !== undefined;
});
const description = computed(() => {
  switch (props.type) {
    case FILE_TYPE.IMAGE:
      return 'studio.prj_prod.this_prod.edit_gamepreview_spotlights_image_title';
    case FILE_TYPE.VIDEO:
      return 'studio.prj_prod.this_prod.edit_gamepreview_spotlights_video_file_title';
    case FILE_TYPE.LINK:
      return 'studio.prj_prod.this_prod.edit_gamepreview_spotlights_video_link_title';
    case FILE_TYPE.AWARD:
      return 'studio.prj_prod.this_prod.edit_gamepreview_spotlights_image_title';
    default:
      return 'studio.prj_prod.this_prod.edit_gamepreview_spotlights_image_title';
  }
});

const defaultAcceptFileExt: string[] = [
  FILE_EXTENSION.PNG,
  FILE_EXTENSION.JPG,
  FILE_EXTENSION.JPEG,
  FILE_EXTENSION.GIF,
  FILE_EXTENSION.WEBP,
  FILE_EXTENSION.WEBM,
  FILE_EXTENSION.MP4
];

const acceptedMaxSize = computed(() => {
  return props.maxSize || 30 * SIZE_UNIT;
});

const acceptFileExtAttribute = computed(() => {
  if (props.extensions && props.extensions?.length > 0) {
    return props.extensions?.join(', ');
  }

  return defaultAcceptFileExt.join(', ');
});

const acceptFileSize = computed(() => {
  return props.maxSize ? props.maxSize / SIZE_UNIT : 30;
});

const acceptFileExtAttributeLabel = computed(() => {
  const acceptFileExt = acceptFileExtAttribute.value.replaceAll('.', '').toUpperCase();
  return acceptFileExt;
});

const acceptFileExtensions = computed(() => {
  if (props.extensions) {
    return props.extensions;
  }

  return defaultAcceptFileExt.map((ext: string) => ext.replace('.', ''));
});
const allowExtensions = computed(() => {
  return acceptFileExtensions.value.map((ext: string) => `.${ext}`).join(',');
});

const canEditFile = ref<boolean>(true);

const resultImage = useFieldValue<ImageLanguageType>(props.name);
const setResultImage = useSetFieldValue<ImageLanguageType>(props.name);

const movieUrl = ref<string>(resultImage.value?.movieUrl || '');

const error = useFieldError(`${props.name}.image`);
const errorLink = useFieldError(`${props.name}.movieUrl`);
const setError = useSetFieldError(`${props.name}.image`);

const imageValidationRules = computed<ValidationRule>(() => {
  const rules: ValidationRule = {
    file_count: {
      max: 1,
      message: t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert2'),
      showError: false
    },
    max_size: {
      size: acceptedMaxSize.value,
      message: t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert4'),
      showError: false
    },
    extension: {
      extensions: acceptFileExtensions.value,
      message: t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert3'),
      showError: false
    }
  };
  if (props.required) {
    rules.required = {
      value: true,
      message:
        props.requiredMessage ??
        t('studio.prj_prod.this_prod.edit_gamepreview_add_img_required msg'),
      showError: false
    };
  } else {
    delete rules.required;
  }
  return rules;
});
const isRequiredError = computed(() => {
  return error.value && JSON.parse(error.value).rule === RuleNames.REQUIRED;
});

const onUploadFileFromStorage = async () => {
  await getGroupProjectsProductsCounts(selectedGroupId.value);
  if (projectsProductsCounts.value?.projectCount === 0) {
    showAlert({
      content: t('studio.group.collection.storage_select_prj_none_popup')
    });
    return;
  }
  showStorageDialog();
};

const onClearUploadedFile = () => {
  if (props.isDisabled) {
    if (props.showMessageWhenDisabled) {
      showAlert({
        content: props.disabledMessage || ''
      });
      return;
    }
    return;
  }

  setResultImage({
    image: '',
    lang: resultImage.value?.lang ?? selectedGroupInfo.value?.languageCd,
    name: '',
    fileId: '',
    linkCdn: '',
    thumbnail: '',
    fileSize: 0,
    fileType: '',
    movieUrl: movieUrl.value
  });

  emits('removeFile', props.fileId, FileRemoveType.REMOVE_FILE_ONLY);
};

const uploadToCDN = async (files: File[]) => {
  if (!(await checkFileNameMaxLength(files[0].name || resultImage.value.name || ''))) {
    return;
  }

  const isConvertWebp: boolean = true;
  const result = await uploadFileApi([files[0]], 'images', isConvertWebp);

  if (result) {
    let type = 'image';
    if (result[0].filetype.includes('video')) {
      type = 'video';
    }
    const image = {
      image: result[0].linkCdn,
      fileId: result[0].fileId,
      linkCdn: result[0].linkCdn,
      lang: resultImage.value?.lang ?? props.selectedLanguage ?? selectedGroupInfo.value?.languageCd,
      thumbnail: result[0].thumbnail || result[0].link,
      name: files[0].name,
      fileType: isConvertWebp ? files[0].type : result[0].filetype,
      fileTypeRaw: files[0].type,
      fileSize: result[0].filesize,
      movieUrl: resultImage.value?.movieUrl || '',
      type: props.type ?? resultImage.value?.type ?? type
    };

    setResultImage(image);
    setError(undefined);
    emits('addFile', image, props.fileId);
  }
};

const openDialog = async (file: string, fileName?: string) => {
  if (!(await checkFileNameMaxLength(fileName || resultImage.value.name || ''))) {
    return;
  }

  const result: ResultMergeImage = await showDialog({
    component: shallowRef(ImageDialog),
    props: {
      type: fileTypeRef.value,
      background: file.startsWith('http') ? `${file}?ts=${Date.now()}` : file,
      size: props.resolution,
      hideLogo: props.isHiddenAddLogo,
      autoCropWidth: props.cropBox?.width,
      autoCropHeight: props.cropBox?.height,
      disableOpacity: props.disableOpacity,
      stencilSize: props.stencilSize,
      safeArea: props.safeArea,
      fileName,
      dialogName: props.dialogName,
      isGuideShown: props.isGuideShown,
      isZoomLimit: props.isZoomLimit,
      guideType: props.guideType,
      safeAreaLogo: props.safeAreaLogo,
      hideUploadToStorage: isCollection.value
    }
  });

  if (!result) {
    // if (isOpenBySelectFile) {
    //   onClearUploadedFile();
    // }
    return;
  }

  await uploadToCDN([
    convertBase64ToFile(
      result.base64,
      fileName || resultImage.value.name || '',
      IMAGE_MIMES_TYPE.PNG
    )
  ]);
};

// const getFileNameWithoutExtension = (fileName: string): string => {
//   if (fileName.includes('.')) {
//     const revertedFileName = fileName.split('').reverse().join('');
//     const firstDotIndex = revertedFileName.indexOf('.');
//     return revertedFileName.slice(firstDotIndex + 1).split('').reverse().join('');
//   }

//   return fileName;
// };

const handleFileSelect = async (files: File[]) => {
  // Check ICO file validation first
  if (props.extensions?.includes(IMAGE_TYPE.ICO)) {
    const file = files[0];
    if (!(await checkFileNameMaxLength(file.name || resultImage.value.name || ''))) {
      return;
    }
    if (
      !file.name.toLowerCase().endsWith(IMAGE_FILE_EXTENSION.ICO) ||
      !(await validateIcoFile(file))
    ) {
      setResultImage({
        ...resultImage.value,
        fileType: 'invalid-ico'
      });
      return;
    }

    await handleIcoFileUpload(file);
    return;
  }

  // image type
  const fileURL = URL.createObjectURL(files[0]);

  const isAnimated = await isAnimatedWebP(fileURL);
  isAnimated && gifTypeArray.push(IMAGE_MIMES_TYPE.WEBP);

  if (files.length === 1 && !gifTypeArray.some((type: string) => files[0].type.includes(type))) {
    const image = await convertFileToBase64(files[0]);
    canEditFile.value = true;

    const fileName = files[0].name;
    fileTypeRef.value = files[0].type;
    await openDialog(image, fileName);
    return;
  }

  // case gif or mp4
  canEditFile.value = false;
  let dimension;

  if (files[0].type.includes(IMAGE_MIMES_TYPE.GIF)) {
    // gif
    dimension = await getImageDimension(files[0]);
    if (
      !isCorrectResolution(
        dimension?.width ?? 0,
        dimension?.height ?? 0,
        props.cropBox?.width ?? 0,
        props.cropBox?.height ?? 0
      )
    ) {
      // onClearUploadedFile();
      setResultImage({
        image: '',
        lang: resultImage.value?.lang ?? selectedGroupInfo.value?.languageCd,
        name: '',
        fileId: '',
        linkCdn: '',
        thumbnail: '',
        fileSize: 0,
        fileType: '',
        movieUrl: movieUrl.value
      });

      await showAlert({
        content: t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert1')
      });
      return;
    }
  } else {
    // mp4
    dimension = await getVideoDimension(files[0]);
  }

  await uploadToCDN(files);
};

const onRemoveFromListFile = () => {
  if (!props.inList || props.fileId === undefined) {
    return;
  }
  emits('removeFile', props.fileId, FileRemoveType.REMOVE_FILE_FROM_LIST);
};

const showStorageDialog = async () => {
  if (projectId === undefined) {
    const auth = await app.getMenuPermission(MenuAuthorityIds.PROJECT_PRODUCT);
    if (!auth.isReadable) {
      await showAlert({ content: t('studio.common.popup_case_g_not_view') });
      return;
    }
    const result: string = await showDialog({
      component: shallowRef(StorageProjectSelectingDialog)
    });
    selectedProjectId.value = result;
  }
  let acceptStorageExtensions = '';
  if (props.extensions && props.extensions.length > 0) {
    acceptStorageExtensions = props.extensions
      .map((ext: string) => MAP_FILE_TYPE_TO_MINE_TYPE[ext])
      .join(',');
    if (props.extensions?.includes(IMAGE_TYPE.ICO)) {
      acceptStorageExtensions += `,${ICO_MS_MIME_TYPE}`;
    }
  }

  const storageProps: StorageUploadPopupProps = {
    projectId: selectedProjectId.value ? selectedProjectId.value : (projectId as string),
    isAttachedFile: true,
    isChooseOneFile: true,
    uploadOptions: {
      accept:
        acceptStorageExtensions ||
        (props.type === FILE_TYPE.VIDEO
          ? ARRAY_VIDEO_FILE_EXTENSION
          : ARRAY_IMAGE_FILE_EXTENSION_WITHOUT_ICO),
      maxSize: acceptedMaxSize.value * SIZE_UNIT // 30KB * 1024 = 30MB
    }
  };
  const result: StorageUploadFileType[] = await showDialog({
    component: shallowRef(StorageUpload),
    props: {
      ...storageProps
    }
  });

  if (!result) {
    return;
  }

  if (props.extensions?.includes(IMAGE_TYPE.ICO)) {
    setResultImage({
      image: result[0].linkCdn,
      fileId: result[0].fileId,
      linkCdn: result[0].linkCdn,
      lang: resultImage.value?.lang ?? selectedGroupInfo.value?.languageCd,
      thumbnail: result[0].thumbnail || result[0].linkCdn,
      name: result[0].fullFileName,
      fileType: result[0].fileType,
      fileSize: result[0].fileSize,
      movieUrl: resultImage.value?.movieUrl || ''
    });
    emits('addFile', resultImage.value, props.fileId);
    return;
  }

  if (result.length > 1) {
    await showAlert({
      content: t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert2')
    });
    return;
  }

  if (result[0].fileSize > acceptedMaxSize.value * SIZE_UNIT) {
    await showAlert({
      content: t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert4')
    });
    return;
  }
  startLoading(1);
  const linkCdn = result[0].linkCdn + '?ts=' + Date.now();
  const image = await convertCdnToBase64(linkCdn);

  // if file is not gif or video, open dialog to resize to correct resolution
  const gifTypeArray = [IMAGE_TYPE.GIF, VIDEO_TYPE.MP4, VIDEO_TYPE.WEBM];
  const isAnimated = await isAnimatedWebP(linkCdn);
  isAnimated && gifTypeArray.push(IMAGE_TYPE.WEBP);

  if (!gifTypeArray.some((type: string) => result[0].fileType.includes(type))) {
    canEditFile.value = true;
    endLoading(1);
    await openDialog(image, result[0].fullFileName);
    return;
  }
  // check resolution of gif
  canEditFile.value = false;
  const imageDimension = await getImageDimension(image);
  endLoading(1);

  if (
    result[0].fileType.includes(IMAGE_MIMES_TYPE.GIF) ||
    result[0].fileType.includes(IMAGE_TYPE.GIF)
  ) {
    if (
      !isCorrectResolution(
        imageDimension?.width ?? 0,
        imageDimension?.height ?? 0,
        props.cropBox?.width ?? 0,
        props.cropBox?.height ?? 0
      )
    ) {
      onClearUploadedFile();
      await showAlert({
        content: t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert1')
      });
      return;
    }
  }

  setResultImage({
    image,
    linkCdn: result[0].linkCdn,
    lang: resultImage.value?.lang ?? selectedGroupInfo.value?.languageCd,
    thumbnail: result[0].thumbnail,
    name: result[0].fullFileName,
    fileType: result[0].fileType,
    fileSize: result[0].fileSize,
    fileId: result[0].fileId,
    movieUrl: movieUrl.value
  });
};

watch(
  () => error.value,
  async () => {
    if (error.value && JSON.parse(error.value).rule !== RuleNames.REQUIRED) {
      await throwContentError(JSON.parse(error.value).message);
      if (!resultImage.value?.linkCdn && props.requiredMessage) {
        setError(generateErrorMsg(props.requiredMessage, RuleNames.REQUIRED));
      } else {
        setError('');
      }
    }
  }
);

const formattedFileName = computed(() => {
  if (!resultImage.value?.name) {
    return '';
  }

  if (props.expandFileNameByRightWing) {
    const width = storage.isOpenRightWing ? props.fileNameWidth : props.expandedWidth ?? 600;

    return convertShortFileNameWithContainerWidth(resultImage.value.name, width);
  }

  const width = !expandFileNameByEditor.value ? props.fileNameWidth : props.expandedWidth ?? 600;

  return convertShortFileNameWithContainerWidth(resultImage.value.name, width);
});

watch(
  () => resultImage.value,
  (newValue: ImageLanguageType) => {
    if (!newValue) {
      canEditFile.value = true;
      return;
    }

    if (newValue.name) {
      const extension = newValue.name.split('.').pop()?.toLowerCase();
      canEditFile.value = ![IMAGE_TYPE.GIF, VIDEO_TYPE.MP4, VIDEO_TYPE.WEBM].includes(
        extension || ''
      );
    }
  },
  { immediate: true, flush: 'post', deep: true }
);

const handleIcoFileUpload = async (file: File) => {
  try {
    const result = await uploadFileApi([file], 'images', false);

    if (result && result[0]) {
      setResultImage({
        image: result[0].linkCdn,
        fileId: result[0].fileId,
        linkCdn: result[0].linkCdn,
        lang: resultImage.value?.lang ?? selectedGroupInfo.value?.languageCd,
        thumbnail: result[0].thumbnail || result[0].linkCdn,
        name: file.name,
        fileType: file.type,
        fileSize: result[0].filesize,
        movieUrl: resultImage.value?.movieUrl || ''
      });

      emits('addFile', resultImage.value, props.fileId);
    }
  } catch (error) {
    // console.error('Error uploading ICO file:', error);
  }
};

const editable = computed(() => {
  const notGif = resultImage.value?.fileType ? !gifTypeArray.includes(resultImage.value?.fileType) : resultImage.value?.linkCdn?.slice(-4) !== IMAGE_FILE_EXTENSION.GIF;
  return resultImage.value?.linkCdn && notGif && props.showEditButton;
});
</script>
