<template>
  <div class="mt-auto flex items-center gap-24 pt-20 pb-32 px-32">
    <div class="flex-1 flex items-center gap-16">
      <div class="shrink-0 flex items-center gap-8">
        <p class="mr-8 text-md font-bold leading-sm">
          {{ $t('studio.prj_prod.this_prod.edit_gamepreview_import_pop_image_editor_msg2') }}
        </p>
        <button
          v-uploadable="{ accept: IMAGE_EXTENSION_WITHOUT_GIF }"
          class="stds-button stds-button-outline rounded-lg px-12 min-h-[3.2rem] !min-w-[initial]"
          size="sm"
          variant="outline"
          :disabled="hideLogo"
          @fileSelected="({ detail }: CustomEvent) => handleLogoSelected(detail.image, detail.dimension)"
        >
          <span class="py-[0.5rem] text-btn3 leading-btn3">{{
            $t('studio.prj_prod.this_prod.edit_gamepreview_import_pop_image_editor_local_btn')
          }}</span>
        </button>

        <s-button
          size="sm"
          variant="outline"
          class="!min-w-[initial]"
          :disabled="hideLogo"
          @click="openStorageUpload"
        >
          {{
            $t('studio.prj_prod.this_prod.edit_gamepreview_import_pop_image_editor_storage_btn')
          }}
        </s-button><!--ToDo: get logo from store-->
      </div>
      <div class="ml-auto flex items-center divide-x divide-solid divide-border">
        <div class="flex items-center h-24 gap-16 px-16">
          <button type="button" class="inline-flex" @click="handleFlipImage">
            <s-icon
              size="4xl"
              icon="ic-v2-editor-reflect-line"
              class="text-on-surface-elevation-2"
            />
          </button>

          <button type="button" class="inline-flex" @click="handleRotateImage">
            <s-icon
              size="4xl"
              icon="ic-v2-editor-rotation-line"
              class="text-on-surface-elevation-2"
            />
          </button>

          <button
            :isDisabled="logoImage === ''"
            type="button"
            class="inline-flex"
            :class="{ 'cursor-default': logoImage === '' }"
            @click="handleDeleteImage"
          >
            <s-icon
              size="4xl"
              icon="ic-v2-object-delete-line"
              :class="logoImage !== '' ? 'text-on-surface-elevation-2' : 'text-disabled-variant-1'"
            />
          </button>
        </div>
        <div class="flex items-center h-24 gap-16 px-16">
          <span class="text-md font-medium leading-sm text-on-surface-elevation-2">{{
            $t('studio.prj_prod.this_prod.edit_gamepreview_import_pop_image_editor_logo')
          }}</span>
          <div
            class="flex items-center h-32 my-[-.4rem] px-[.6rem] bg-[var(--stds-glob-color-gray60)] rounded-lg"
            :class="
              !logoImage || disableOpacity
                ? 'text-disabled-variant-1 pointer-events-none'
                : 'text-on-surface-elevation-2'
            "
          >
            <s-icon size="4xl" icon="ic-v2-editor-opacity-line" />
            <input-text
              v-model="opacityLogo"
              noContainerClass
              :disabled="!logoImage"
              maxLength="3"
              :countable="false"
              :clearable="false"
              :type="InputTextTypes.Number"
              :min="1"
              :max="100"
              :inputClass="inputClass"
            />
            <span class="text-md font-medium leading-sm">%</span>
          </div>
        </div>
        <div class="flex items-center h-24 px-16">
          <s-contents-popup
            placement="top-start"
            trigger="click"
            :distance="15"
            :offset="[0, 0]"
            :minWidth="232"
            :theme="'studio-color-palette'"
          >
            <template #target>
              <button type="button" class="inline-flex items-center gap-8">
                <s-tooltip
                  arrow
                  :content="
                    $t(
                      'studio.prj_prod.this_prod.edit_gamepreview_import_pop_image_editor_background_color_full'
                    )
                  "
                  :duration="0"
                  useFlip
                  flipOnUpdate
                  placement="top"
                  trigger="mouseenter focus"
                  :zIndex="9999"
                  :theme="'studio-tooltip'"
                >
                  <template #target>
                    <span class="text-md font-medium leading-sm text-on-surface-elevation-2">
                      {{
                        $t(
                          'studio.prj_prod.this_prod.edit_gamepreview_import_pop_image_editor_background_color_abbr'
                        )
                      }}
                    </span>
                  </template>
                </s-tooltip>
                <span
                  v-if="pickColor !== 'transparent' && pickColor !== 'None'"
                  class="inline-flex w-24 h-24 rounded-[.6rem]"
                  :class="{ 'border-1 border-solid border-border': pickColor === '#FFFFFF' }"
                  :style="{ 'background-color': pickColor }"
                ></span>
                <span v-else class="w-24 h-24 cursor-pointer rounded-[.6rem]">
                  <img
                    src="@/assets/images/product/color-palette-transparent.svg"
                    alt=""
                    class="w-full h-full object-cover"
                  />
                </span>
              </button>
            </template>
            <template #header>
              <s-contents-popup-header>
                {{
                  $t(
                    'studio.prj_prod.this_prod.edit_gamepreview_import_pop_image_editor_background_color_full'
                  )
                }}
              </s-contents-popup-header>
            </template>
            <template #contents>
              <s-contents-popup-content-body>
                <div class="flex flex-col gap-16">
                  <div class="flex flex-wrap gap-4">
                    <template v-for="color in colors" :key="color">
                      <span
                        class="w-24 h-24 cursor-pointer rounded-[.6rem]"
                        :class="{ 'border-1 border-solid border-border': color === '#FFFFFF' }"
                        :style="{ background: color }"
                        @click="pickColor = color"
                      ></span>
                    </template>
                  </div>
                  <div class="flex">
                    <!-- 투명 -->
                    <span
                      class="w-24 h-24 cursor-pointer rounded-[.6rem]"
                      @click="pickColor = 'transparent'"
                    >
                      <img
                        src="@/assets/images/product/color-palette-transparent.svg"
                        alt=""
                        class="w-full h-full object-cover"
                      />
                    </span>
                    <div class="ml-auto flex items-center gap-8">
                      <!-- 스포이드 -->
                      <div>
                        <input
                          v-if="!isSupported"
                          v-model="pickColor"
                          type="color"
                          class="w-full opacity-0 absolute"
                        />
                        <button
                          type="button"
                          class="inline-flex items-center gap-8"
                          @click="handleChangeBgColor"
                        >
                          <s-icon
                            size="4xl"
                            icon="ic-v2-editor-color-picker-line"
                            class="text-on-surface-elevation-2"
                          />
                        </button>
                      </div>
                      <div class="flex items-center gap-4">
                        <span
                          v-if="pickColor !== 'transparent' && pickColor !== 'None'"
                          class="w-24 h-24 cursor-pointer rounded-[.6rem]"
                          :class="{
                            'border-1 border-solid border-border': pickColor === '#FFFFFF'
                          }"
                          :style="{ background: pickColor }"
                        ></span>
                        <span v-else class="w-24 h-24 cursor-pointer rounded-[.6rem]">
                          <img
                            src="@/assets/images/product/color-palette-transparent.svg"
                            alt=""
                            class="w-full h-full object-cover"
                          />
                        </span>
                        <input
                          type="text"
                          :value="pickColor === 'transparent' ? 'None' : pickColor"
                          class="w-68 tracking-[0] text-md font-medium leading-sm text-on-surface-elevation-1 outline-0"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </s-contents-popup-content-body>
            </template>
          </s-contents-popup>
        </div>
      </div>
    </div>

    <div class="shrink-0 space-x-16">
      <s-button
        v-if="showUploadToStorage"
        variant="outline"
        class="!min-w-120"
        @click="handleUpload"
      >
        {{
          $t('studio.prj_prod.this_prod.edit_gamepreview_import_pop_image_editor_save_storage_btn')
        }}
      </s-button>
      <s-button v-else variant="outline" class="!min-w-120 opacity-0 cursor-default" />
      <s-button
        variant="primary"
        class="!min-w-120"
        :isDisabled="!isBg && !isLogo"
        @click="handleMergeImage"
      >
        {{ $t('studio.common.popup_case_apply_btn') }}
      </s-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineModel, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import StorageUpload from '@/components/storage/storage-upload.vue';
import InputText from '@/components/validation/input-text.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showDialog } from '@/composables/useDialog';
import { useEyeDropper } from '@/composables/useEyeDropper';
import {
  ARRAY_IMAGE_FILE_EXTENSION_WITHOUT_ICO,
  IMAGE_EXTENSION_WITHOUT_GIF
} from '@/constants/file.const';
import { InputTextTypes } from '@/enums/validation.enum';
import type { StorageUploadPopupProps } from '@/types/common/popup.type';
import type { OrNull } from '@/types/core.type';
import type { ImageDimension } from '@/types/image/image.type';
import type { StorageUploadFileType } from '@/types/storage-upload/storage-upload-file.type';
import { convertCdnToBase64 } from '@/utils/file.util';

const { checkProductPermission, checkMenuWritable } = useApp();

const { t } = useI18n();

const opacityLogo = defineModel<string>('opacityLogo');
const pickColor = defineModel<string>('pickColor');

const route = useRoute();
const { productNo: collectionId } = route.query;

const { open, sRGBHex, isSupported } = useEyeDropper();

const logoImage = ref<string>('');

const props = defineProps<{
  isBg: boolean;
  isLogo: boolean;
  hideLogo?: boolean;
  disableOpacity?: boolean;
  hideUploadToStorage?: boolean;
}>();

const emits = defineEmits<{
  logoImg: [v: string];
  logoDimension: [v: OrNull<ImageDimension>];
  onMerge: [v: void];
  onRotate: [v: void];
  onFlip: [v: void];
  onUploadStorage: [v: void];
  onDeleteLogo: [v: void];
}>();

const inputClass = computed(() => {
  return `studio-input-number w-[2.6rem] text-md font-medium leading-sm text-right bg-transparent ${
    (!logoImage.value || props.disableOpacity) && 'text-disabled-variant-1 pointer-events-none'
  }`;
});

const showUploadToStorage = computed(() => {
  return !props.hideUploadToStorage;
});

const colors = [
  '#191919',
  '#3B3D45',
  '#484A51',
  '#6C6F7A',
  '#93969F',
  '#C9CCCF',
  '#FFFFFF',
  '#001A5C',
  '#0031AD',
  '#1355FF',
  '#3D73FF',
  '#6892FF',
  '#92B1FF',
  '#BDCFFF'
];

const handleLogoSelected = (image: string, dimension: ImageDimension) => {
  const allowExtensions = IMAGE_EXTENSION_WITHOUT_GIF.map((ext: string) => `data:${ext}`);

  const isAllowed = !!allowExtensions.find((ext: string) => {
    return image.includes(ext);
  });
  if (!isAllowed) {
    showAlert({
      content: t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert3')
    });
    return;
  }

  handleUpdateLogoImage(image);
  onLogoImageDimension(dimension);
};

const handleUpdateLogoImage = async (image: string) => {
  if (image === null) {
    return;
  }
  logoImage.value = image;
  emits('logoImg', image);
};

const onLogoImageDimension = (dimension: OrNull<ImageDimension>) => {
  emits('logoDimension', dimension);
};

const handleMergeImage = () => {
  emits('onMerge');
};

const handleRotateImage = () => {
  emits('onRotate');
};

const handleFlipImage = () => {
  emits('onFlip');
};

const handleUploadStorage = () => {
  emits('onUploadStorage');
};

const handleDeleteImage = () => {
  logoImage.value = '';
  handleUpdateLogoImage('');
  emits('onDeleteLogo');
};

const handleChangeBgColor = () => {
  if (isSupported) {
    open();
  }
};

const openStorageUpload = async () => {
  const props: StorageUploadPopupProps = {
    isAttachedFile: true,
    isChooseOneFile: true,
    uploadOptions: {
      accept: ARRAY_IMAGE_FILE_EXTENSION_WITHOUT_ICO
    }
  };
  const result: StorageUploadFileType[] = await showDialog({
    component: shallowRef(StorageUpload),
    isOverlayDialog: true,
    props: {
      ...props
    }
  });
  if (!result || !result[0].linkCdn) {
    return;
  }
  const image = await convertCdnToBase64(`${result[0].linkCdn}?timestamp=${new Date().getTime()}`);
  const i = new Image();
  i.src = image;
  i.onload = function() {
    const dimension: ImageDimension = {
      width: i.width,
      height: i.height
    };
    handleLogoSelected(image, dimension);
  };
};

const handleUpload = async () => {
  if (collectionId) {
    if (!(await checkMenuWritable())) {
      return;
    }
  } else if (!(await checkProductPermission())) {
    return;
  }

  handleUploadStorage();
};

watch(
  () => sRGBHex.value,
  (color: string) => {
    pickColor.value = color;
  }
);
</script>

<style scoped>
.stds-contents-popup-header > .stove-ui-icon {
  width: 0 !important;
  min-width: 0 !important;
  padding-right: 2rem;
}

.text-disabled-variant-1 input {
  color: var(--tw-shadow-colored);
}
</style>
