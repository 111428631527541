<template>
  <template v-if="props.guideType === PRODUCT_PAGE_IMAGE_EDITOR_GUIDE_TYPE.CUSTOM">
    <div
      :class="`border-[2px] border-solid border-[#FC4420] absolute top-1/2 transform -translate-y-1/2 pointer-events-none`"
      :style="{
        ...safeAreaStyles,
        ...(safeArea && safeArea.left ? { left: `${safeArea.left}` } : {left: `${safeAreaComputed}px`})
      }"
    ></div>
    <div
      v-if="!hideLogo"
      class="border-[2px] border-solid border-[#E8FC68] absolute top-1/2 -translate-y-1/2 transform left-[24rem] pointer-events-none"
      :style="safeAreaLogoStyles"
    >
      <p class="text-bold text-[#E8FC68]">LOGO</p>
    </div>
  </template>

  <div
    v-else
    class="border-[2px] border-solid border-[#FC4420] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
    :style="safeAreaStyles"
  >
    <div
      v-if="!hideLogo"
      class="w-[90%] h-[50%] border-[2px] border-solid border-[#E8FC68] absolute left-1/2 transform -translate-x-1/2 bottom-[1rem]"
      :style="safeAreaLogoStyles"
    >
      <p class="text-bold text-[#E8FC68]">LOGO</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, type Ref, toRefs } from 'vue';

import { PRODUCT_PAGE_IMAGE_EDITOR_GUIDE_TYPE } from '@/enums/product-page.enum';
import type { ImageDimension } from '@/types/image/image.type';

const props = defineProps<{
  safeArea?: ImageDimension;
  hideLogo?: boolean;
  guideType?: PRODUCT_PAGE_IMAGE_EDITOR_GUIDE_TYPE;
  safeAreaLogo?: ImageDimension;
}>();

const { safeAreaLogo, safeArea } = toRefs(props);

const buildStylesByDimensionInfo = (dimension: Ref<ImageDimension | undefined>) => {
  if (dimension.value) {
    return {
      width: dimension.value.width ? `${dimension.value.width}px` : undefined,
      height: dimension.value.height ? `${dimension.value.height}px` : undefined,
      top: dimension.value.top,
      right: dimension.value.right,
      bottom: dimension.value.bottom,
      left: dimension.value.left
    };
  }
  return {};
};

const convertRemToPixels = (rem: number) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

const safeAreaLogoStyles = computed(() => {
  return buildStylesByDimensionInfo(safeAreaLogo);
});

const safeAreaStyles = computed(() => {
  return buildStylesByDimensionInfo(safeArea);
});

const safeAreaComputed = computed(() => {
  const pixel = convertRemToPixels(24);
  return pixel + (safeAreaLogo.value?.width ?? 0);
});
</script>
