<template>
  <div class="pr-8 flex items-center gap-16">
    <div class="flex-1 flex items-center gap-8">
      <p class="text-md font-bold leading-sm">
        {{ $t('studio.prj_prod.this_prod.edit_gamepreview_import_pop_image_editor_title') }}
      </p>
      <p class="text-md font-medium leading-sm">
        {{ name ? $t(name) : $t(convertImageSizeToName(size)) }}
      </p>
    </div>
    <div v-if="isGuideShown" class="shrink-0 ml-16 flex items-center gap-8">
      <div class="flex items-center gap-4">
        <s-tooltip
          arrow
          :content="`<p class='text-center'>${contentGuide}</p>`"
          :duration="0"
          useFlip
          flipOnUpdate
          placement="bottom"
          trigger="mouseenter focus"
          :theme="'studio-tooltip'"
          :zIndex="2500"
          :allowHTML="true"
          :offset="[0, 8]"
          class="inline-flex"
        >
          <template #target>
            <s-icon
              size="xl"
              icon="ic-v2-state-info-circle-line"
              class="text-on-surface-elevation-3"
            />
          </template>
        </s-tooltip>
        <span class="text-md leading-sm text-on-surface-elevation-3">
          {{ $t('studio.prj_prod.this_prod.edit_gamepreview_import_pop_image_editor_msg1') }}
        </span>
      </div>
      <s-switch id="switch-sm" v-model="guide" size="md" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineModel } from 'vue';
import { useI18n } from 'vue-i18n';

import { convertImageSizeToName } from '@/utils/file.util';

const props = defineProps<{
  size: string;
  hideLogo?: boolean;
  name?: string;
  isGuideShown?: boolean;
}>();

const { t } = useI18n();

const guide = defineModel<boolean>('guide');

const contentGuide = computed(() =>
  props.hideLogo
    ? t('studio.prj_prod.this_prod.edit_gamepreview_import_pop_image_editor_info_tool_tip2')
    : t('studio.prj_prod.this_prod.edit_gamepreview_import_pop_image_editor_info_tool_tip1')
);
</script>
