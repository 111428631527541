export const validateIcoFile = (file: File): Promise<boolean> => {
  return new Promise((resolve: (isValidIco: boolean) => void) => {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const buffer = event.target?.result as ArrayBuffer;
      const data = new Uint8Array(buffer);

      // Check ICO header [0x00, 0x00, 0x01, 0x00]
      const isValidIco =
        data.length >= 4 &&
        data[0] === 0x00 &&
        data[1] === 0x00 &&
        data[2] === 0x01 &&
        data[3] === 0x00;

      resolve(isValidIco);
    };

    reader.onerror = () => resolve(false);
    reader.readAsArrayBuffer(file);
  });
};
