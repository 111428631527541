import { useNuxtApp } from 'nuxt/app';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { fetchProductPageDetailApi } from '@/apis/product-page.api';
import type { ProductPageDetailResponse } from '@/types/product-page/product-page-response.type';

export const useProductPageStore = defineStore('productPageStore', () => {
  const { t } = useNuxtApp().$i18n as any;

  const productPageDetail = ref<ProductPageDetailResponse>();

  const showOnlyRequiredFields = ref<boolean>(false);
  const isOpenedEditor = ref<boolean>(false);
  const isClickedSave = ref<boolean>(false);
  const isEnableRecommendSettings = ref<boolean>(false);

  const defaultDescription = ref<string>(
    `<p style="color: rgb(59, 61, 69); font-size: 13px; font-weight: 500;"> ${t(
      'studio.prj_prod.this_prod.edit_summary_place_holder1'
    )} </p> <ul> <li style="font-size: 13px; color: rgb(108, 111, 122); font-weight: 500;">${t(
      'studio.prj_prod.this_prod.edit_summary_place_holder2'
    )}</li> <li style="font-size: 13px; color: rgb(108, 111, 122); font-weight: 500;">${t(
      'studio.prj_prod.this_prod.edit_summary_place_holder3'
    )}</li> <li style="font-size: 13px; color: rgb(108, 111, 122); font-weight: 500;">${t(
      'studio.prj_prod.this_prod.edit_summary_place_holder4'
    )}</li> <li style="font-size: 13px; color: rgb(108, 111, 122); font-weight: 500;">${t(
      'studio.prj_prod.this_prod.edit_summary_place_holder5'
    )}</li> <li style="font-size: 13px; color: rgb(108, 111, 122); font-weight: 500;">${t(
      'studio.prj_prod.this_prod.edit_summary_place_holder6'
    )}</li> <li style="font-size: 13px; color: rgb(108, 111, 122); font-weight: 500;">${t(
      'studio.prj_prod.this_prod.edit_summary_place_holder7'
    )}</li></ul>`
  );
  const productDescription = ref<string>(defaultDescription.value);

  const expandFileNameByEditor = computed(() => isOpenedEditor.value);

  const fetchProductPageInfo = async (pageId: string) => {
    const data = await fetchProductPageDetailApi(pageId);

    if (data) {
      productPageDetail.value = data;
    }
  };

  const setProductDescription = (description: string) => {
    productDescription.value = description;
  };

  const setProductPageDetail = (page?: ProductPageDetailResponse) => {
    productPageDetail.value = page;
  };

  const setPageEmpty = () => {
    productPageDetail.value = undefined;
    isOpenedEditor.value = false;
    isClickedSave.value = false;
    isEnableRecommendSettings.value = false;
    productDescription.value = defaultDescription.value;
  };

  return {
    // variables
    productPageDetail,
    showOnlyRequiredFields,
    productDescription,
    isOpenedEditor,
    expandFileNameByEditor,
    defaultDescription,
    isClickedSave,
    isEnableRecommendSettings,
    // functions
    fetchProductPageInfo,
    setProductDescription,
    setProductPageDetail,
    setPageEmpty
  };
});
