<template>
  <input-file
    :name="name"
    :rules="{
      file_count: {
        max: 1,
        message: $t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert2'),
        showError: false
      },
      max_size: {
        size: acceptedMaxSize,
        message: $t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert4'),
        showError: false
      },
      extension: {
        extensions: ['jpg', 'png', 'gif', 'webp'],
        message: $t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert3'),
        showError: false
      }
    }"
    multiple
    allowExtensions=".jpg, .png, .gif, .webp'"
    @fileSelect="handleFileSelectAdditional"
  >
    <template #default="{ triggerUpload }">
      <div class="border-t border-border border-solid pt-12">
        <div class="flex flex-col justify-center gap-2">
          <div class="flex items-center gap-12">
            <div class="flex flex-1 items-center gap-12">
              <template v-if="!resultImageAdditional?.linkCdn">
                <span
                  class="studio-upload-image-ready inline-flex h-48 w-48 shrink-0 items-center justify-center overflow-hidden rounded-lg bg-disabled-variant-3"
                >
                  <s-icon
                    size="xl"
                    icon="ic-v2-community-attach-image-line"
                    :class="isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-1'"
                  />
                </span>
                <div class="flex flex-1 flex-col justify-center gap-2">
                  <div class="flex items-center gap-4">
                    <p
                      class="text-xs font-bold leading-xs"
                      :class="
                        isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'
                      "
                    >
                      {{
                        $t(
                          'studio.prj_prod.this_prod.edit_gamepreview_spotlights_video_thumbnail_file'
                        )
                      }}
                    </p>
                  </div>
                  <p
                    class="text-xs leading-xs"
                    :class="isDisabled ? 'text-disabled-variant-1' : 'text-placeholder'"
                  >
                    {{
                      `${$t('studio.common.file_upl_guide1_1', {
                        acceptFileExtAttributeLabel: ['JPG', 'PNG', 'GIF', 'WEBP'].join(', ')
                      })} | ${$t('studio.common.file_upl_guide1_2', { size: 30 })}`
                    }}
                  </p>
                  <p
                    class="text-xs leading-xs"
                    :class="isDisabled ? 'text-disabled-variant-1' : 'text-placeholder'"
                  >
                    {{
                      $t(
                        'studio.prj_prod.this_prod.edit_gamepreview_spotlights_video_file_thumbnail_guide'
                      )
                    }}
                  </p>
                </div>
              </template>
              <template v-else>
                <div class="flex flex-1 items-center gap-12">
                  <span
                    v-if="!isImageFailed"
                    class="relative h-48 w-48 shrink-0 overflow-hidden rounded-lg border-1 border-solid border-border"
                    :class="{ 'after:absolute after:inset-0 after:bg-black/40': isDisabled }"
                  >
                    <img
                      :src="
                        resultImageAdditional?.movieThumbnailUrl?.linkCdn ??
                          resultImageAdditional?.linkCdn
                      "
                      class="h-full w-full object-cover"
                      @error="isImageFailed = true"
                    />
                  </span>
                  <span
                    v-if="isImageFailed"
                    class="studio-upload-image-ready inline-flex h-48 w-48 shrink-0 items-center justify-center overflow-hidden rounded-lg bg-black/20"
                  >
                    <s-icon
                      size="xl"
                      icon="ic-v2-community-chat-broken-image-fill"
                      class="text-[#484a51]"
                    />
                  </span>

                  <div class="flex flex-1 flex-col items-start gap-4">
                    <div class="flex items-center gap-4">
                      <s-tooltip
                        :content="resultImageAdditional.name"
                        :duration="0"
                        :distance="0"
                        useFlip
                        flipOnUpdate
                        placement="bottom"
                        trigger="mouseenter focus"
                        :allowHTML="true"
                        :zIndex="2501"
                      >
                        <template #target>
                          <safe-html
                            class="flex items-center break-all text-xs font-medium leading-xs"
                            :class="
                              isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'
                            "
                            tag="p"
                            :html="formattedFileName"
                          />
                        </template>
                      </s-tooltip>
                      <button type="button" @click="onClearUploadedFile">
                        <s-icon
                          size="xl"
                          icon="ic-v2-control-close-circle-fill"
                          class="shrink-0"
                          :class="
                            isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-4'
                          "
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </template>

              <div class="flex shrink-0 gap-[.6rem]">
                <s-tooltip
                  v-if="resultImageAdditional?.linkCdn"
                  arrow
                  :content="$t('studio.prj_prod.this_prod.edit_gamepreview_hover_msg3')"
                  :duration="0"
                  useFlip
                  flipOnUpdate
                  placement="top"
                  trigger="mouseenter focus"
                  :theme="'studio-tooltip'"
                  :zIndex="2500"
                  :offset="[0, 8]"
                >
                  <template #target>
                    <button
                      type="button"
                      class="inline-flex h-36 w-36 items-center justify-center rounded-full"
                      :class="isDisabled ? 'bg-disabled-variant-3' : 'bg-neutral-variant-2'"
                      @click="openDialog(`${resultImageAdditional.linkCdn}?ts=${Date.now()}` || '')"
                    >
                      <s-icon
                        size="3xl"
                        icon="ic-v2-community-filter-line"
                        :class="
                          isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'
                        "
                        :srOnlyText="$t('studio.prj_prod.this_prod.edit_gamepreview_hover_msg3')"
                      />
                    </button>
                  </template>
                </s-tooltip>
                <s-tooltip
                  arrow
                  :content="$t('studio.prj_prod.this_prod.edit_summary_attatch_msg1')"
                  :duration="0"
                  useFlip
                  flipOnUpdate
                  placement="top"
                  trigger="mouseenter focus"
                  :theme="'studio-tooltip'"
                  :zIndex="2500"
                  :offset="[0, 8]"
                >
                  <template #target>
                    <button
                      type="button"
                      class="inline-flex h-36 w-36 items-center justify-center rounded-full"
                      :class="isDisabled ? 'bg-disabled-variant-3' : 'bg-neutral-variant-2'"
                      @click="triggerUpload"
                    >
                      <s-icon
                        size="3xl"
                        icon="ic-v2-hardware-desktop-line"
                        :class="
                          isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'
                        "
                        :srOnlyText="$t('studio.prj_prod.this_prod.edit_summary_attatch_msg1')"
                      />
                    </button>
                  </template>
                </s-tooltip>
                <s-tooltip
                  arrow
                  :content="$t('studio.prj_prod.this_prod.edit_summary_attatch_msg2')"
                  :duration="0"
                  useFlip
                  flipOnUpdate
                  placement="top"
                  trigger="mouseenter focus"
                  :theme="'studio-tooltip'"
                  :zIndex="2500"
                  :offset="[0, 8]"
                >
                  <template #target>
                    <button
                      type="button"
                      class="inline-flex h-36 w-36 items-center justify-center rounded-full"
                      :class="isDisabled ? 'bg-disabled-variant-3' : 'bg-neutral-variant-2'"
                      @click="onUploadFileFromStorage"
                    >
                      <s-icon
                        size="3xl"
                        icon="ic-v2-object-cabinet-line"
                        :class="
                          isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'
                        "
                        :srOnlyText="$t('studio.prj_prod.this_prod.edit_summary_attatch_msg2')"
                      />
                    </button>
                  </template>
                </s-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </input-file>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import { computed, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { uploadFileApi } from '@/apis/file.api';
import SafeHtml from '@/components/common/safe-html.vue';
import ImageDialog from '@/components/image-editor/image-dialog.vue';
import StorageUpload from '@/components/storage/storage-upload.vue';
import InputFile from '@/components/validation/input-file.vue';
import { showAlert, showDialog } from '@/composables/useDialog';
import {
  IMAGE_MIMES_TYPE,
  IMAGE_TYPE,
  MAP_FILE_TYPE_TO_MINE_TYPE,
  MP4_MIME_TYPE,
  SIZE_UNIT,
  VIDEO_TYPE,
  WEBM_MIME_TYPE
} from '@/constants/file.const';
import { useAppStore } from '@/stores/app.store';
import { useProductPageStore } from '@/stores/product-page.store';
import type { ImageLanguageType, ResultMergeImage } from '@/types/common/file.type';
import type { StorageUploadPopupProps } from '@/types/common/popup.type';
import type { StorageUploadFileType } from '@/types/storage-upload/storage-upload-file.type';
import {
  checkFileNameMaxLength,
  convertBase64ToFile,
  convertCdnToBase64,
  convertFileToBase64,
  getImageDimension,
  getVideoDimension
} from '@/utils/file.util';
import { isAnimatedWebP, isCorrectResolution } from '@/utils/product-page.util';
import { convertShortFileNameWithContainerWidth } from '@/utils/string.util';

const props = withDefaults(
  defineProps<{
    name: string;
    isDisabled: boolean;
  }>(),
  {
    name: '',
    isDisabled: false
  }
);

const { t } = useI18n();

const canEditFile = ref<boolean>(false);
const isImageFailed = ref<boolean>(false);

const productPageStore = useProductPageStore();
const { expandFileNameByEditor } = storeToRefs(productPageStore);

const appStore = useAppStore();
const { startLoading, endLoading } = appStore;

const route = useRoute();
const { projectId } = route.params;

const resultImageAdditional = useFieldValue<ImageLanguageType>(props.name);
const setResultImageAdditional = useSetFieldValue<ImageLanguageType>(props.name);

const formattedFileName = computed(() => {
  if (!resultImageAdditional.value?.name) {
    return '';
  }

  const width = !expandFileNameByEditor.value ? 130 : 640;

  return convertShortFileNameWithContainerWidth(resultImageAdditional.value.name, width);
});

const acceptedMaxSize = 30 * SIZE_UNIT;

const showStorageDialog = async () => {
  const acceptStorageExtensions = ['jpg', 'png', 'gif', 'webp']
    .map((ext: string) => MAP_FILE_TYPE_TO_MINE_TYPE[ext])
    .join(',');

  const storageProps: StorageUploadPopupProps = {
    projectId: projectId as string,
    isAttachedFile: true,
    isChooseOneFile: true,
    uploadOptions: {
      accept: acceptStorageExtensions,
      maxSize: acceptedMaxSize * SIZE_UNIT // 30KB * 1024 = 30MB
    }
  };
  const result: StorageUploadFileType[] = await showDialog({
    component: shallowRef(StorageUpload),
    props: {
      ...storageProps
    }
  });

  if (!result) {
    return;
  }
  if (result[0].fileSize > acceptedMaxSize * SIZE_UNIT) {
    await showAlert({
      content: t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert4')
    });
    return;
  }
  startLoading(1);
  const image = await convertCdnToBase64(result[0].linkCdn);

  // if file is not gif or video, open dialog to resize to correct resolution
  const gifTypeArray = [IMAGE_TYPE.GIF, VIDEO_TYPE.MP4, VIDEO_TYPE.WEBM];
  const isAnimated = await isAnimatedWebP(result[0].linkCdn);
  isAnimated && gifTypeArray.push(IMAGE_TYPE.WEBP);

  if (!gifTypeArray.some((type: string) => result[0].fileType.includes(type))) {
    canEditFile.value = true;
    endLoading(1);
    await openDialog(image, result[0].fullFileName, true);
    return;
  }
  // check resolution of gif
  canEditFile.value = false;
  const imageDimension = await getImageDimension(image);
  endLoading(1);

  if (result[0].fileType.includes(IMAGE_MIMES_TYPE.GIF)) {
    if (!isCorrectResolution(imageDimension?.width ?? 0, imageDimension?.height ?? 0, 757, 426)) {
      onClearUploadedFile();
      await showAlert({
        content: t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert1')
      });
      return;
    }
  }

  setResultImageAdditional({
    image,
    linkCdn: result[0].linkCdn,
    lang: resultImageAdditional.value.lang,
    thumbnail: result[0].thumbnail,
    name: result[0].fullFileName,
    fileType: result[0].fileType,
    fileSize: result[0].fileSize,
    fileId: result[0].fileId,
    movieUrl: ''
  });
};

const uploadToCDN = async (files: File[]) => {
  if (!(await checkFileNameMaxLength(files[0].name || resultImageAdditional.value.name || ''))) {
    return;
  }

  const isConvertWebp: boolean = true;
  const result = await uploadFileApi([files[0]], 'images', isConvertWebp);

  if (result) {
    const image = await convertFileToBase64(files[0]);

    setResultImageAdditional({
      image,
      fileId: result[0].fileId,
      linkCdn: result[0].linkCdn,
      lang: resultImageAdditional.value?.lang || 'ko',
      thumbnail: result[0].thumbnail || result[0].link,
      name: files[0].name,
      fileType: isConvertWebp ? files[0].type : result[0].filetype,
      fileTypeRaw: files[0].type,
      fileSize: result[0].filesize,
      movieUrl: ''
    });
  }
};

const onClearUploadedFile = () => {
  // movieUrl.value = '';
  setResultImageAdditional({
    image: '',
    lang: resultImageAdditional.value.lang,
    name: '',
    fileId: '',
    linkCdn: '',
    thumbnail: '',
    fileSize: 0,
    fileType: '',
    movieUrl: ''
  });
  // emits('update:modelValue', '');
};

const openDialog = async (file: string, fileName?: string, isOpenBySelectFile?: boolean) => {
  if (!(await checkFileNameMaxLength(fileName || resultImageAdditional.value.name || ''))) {
    return;
  }

  const result: ResultMergeImage = await showDialog({
    component: shallowRef(ImageDialog),
    props: {
      type: 'PNG',
      background: file.startsWith('http') ? `${file}?ts=${Date.now()}` : file,
      // size: props.resolution,
      hideLogo: false,
      autoCropWidth: 757,
      autoCropHeight: 426,
      disableOpacity: false,
      stencilSize: {
        width: 680,
        height: 382
      },
      // safeArea: props.safeArea,
      fileName,
      dialogName: 'dialogname',
      isGuideShown: true,
      isZoomLimit: true,
      guideType: false
      // safeAreaLogo: props.safeAreaLogo
    }
  });

  if (!result) {
    if (isOpenBySelectFile) {
      onClearUploadedFile();
    }
    return;
  }

  await uploadToCDN([
    convertBase64ToFile(
      result.base64,
      fileName || resultImageAdditional.value.name || '',
      IMAGE_MIMES_TYPE.PNG
    )
  ]);
};

const onUploadFileFromStorage = () => {
  showStorageDialog();
};

const handleFileSelectAdditional = async (files: File[]) => {
  // image type
  const gifTypeArray = [IMAGE_MIMES_TYPE.GIF, MP4_MIME_TYPE, WEBM_MIME_TYPE];
  const fileURL = URL.createObjectURL(files[0]);

  const isAnimated = await isAnimatedWebP(fileURL);
  isAnimated && gifTypeArray.push(IMAGE_MIMES_TYPE.WEBP);

  if (files.length === 1 && !gifTypeArray.some((type: string) => files[0].type.includes(type))) {
    const image = await convertFileToBase64(files[0]);
    canEditFile.value = true;

    const fileName = files[0].name;
    await openDialog(image, fileName, true);
    return;
  }

  // case gif or mp4
  canEditFile.value = false;
  let dimension;

  if (files[0].type.includes(IMAGE_MIMES_TYPE.GIF)) {
    // gif
    dimension = await getImageDimension(files[0]);
    if (!isCorrectResolution(dimension?.width ?? 0, dimension?.height ?? 0, 757, 426)) {
      onClearUploadedFile();
      await showAlert({
        content: t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert1')
      });
      return;
    }
  } else {
    // mp4
    dimension = await getVideoDimension(files[0]);
  }

  await uploadToCDN(files);
};
</script>
