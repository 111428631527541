<template>
  <cropper
    ref="cropperRef"
    class="w-full h-full min-w-[100rem] min-h-[564px] default-background"
    :src="imgUrl"
    :stencilSize="{
      width: stencilSize?.width || autoCropWidth,
      height: stencilSize?.height || autoCropHeight
    }"
    :stencilProps="{
      handlers: {},
      movable: false,
      resizable: false
    }"
    :defaultSize="
      () => {
        return {
          width: autoCropWidth,
          height: autoCropHeight
        };
      }
    "
    imageClass="border-[.4rem] border-solid border-[var(--stds-sem-color-light-blue)]"
    backgroundClass="!bg-transparent"
    imageRestriction="none"
    @change="onChange"
  />
</template>

<script setup lang="ts">
import 'vue-advanced-cropper/dist/style.css';

import { onMounted, ref, toRefs, watch } from 'vue';
import { Cropper } from 'vue-advanced-cropper';

import { type ImageCopper } from '@/types/common/file.type';
import type { ImageDimension } from '@/types/image/image.type';

const props = defineProps<{
  imgUrl: string;
  fillColor: string;
  autoCropWidth: number;
  autoCropHeight: number;
  stencilSize?: ImageDimension;
}>();

const emit = defineEmits<{
  dataUrl: [v: string];
  dimension: [v: { width: number; height: number }];
  image: [v: ImageCopper];
  fillColor: [v: string];
  click: [];
}>();

const { imgUrl, fillColor, autoCropHeight, autoCropWidth } = toRefs(props);

const cropperRef = ref();
const previewBox = ref();
const previewImage = ref();
const stencilBox = ref();

const onChange = ({ image }: { image: ImageCopper }) => {
  const { canvas } = cropperRef.value.getResult();

  emit('dataUrl', canvas.toDataURL());
  emit('image', image);
};

const rotateImage = (angle: number) => {
  if (!cropperRef.value) {
    return;
  }

  cropperRef.value.rotate(angle);
};

const flipImage = (horizontal: boolean, vertical?: boolean) => {
  if (!cropperRef.value) {
    return;
  }

  cropperRef.value.flip(horizontal, vertical);
};

onMounted(() => {
  stencilBox.value = document.querySelector('.vue-rectangle-stencil');
  stencilBox.value.id = 'parent-stencil-box';
  previewBox.value = document.querySelector(
    '.vue-preview.vue-preview--fill.vue-rectangle-stencil__preview'
  );
  previewImage.value = document.querySelector('.vue-preview__image');
  previewImage.value.classList.add(
    'border-[.4rem]',
    'border-solid',
    'border-[var(--stds-sem-color-light-blue)]'
  );
  const container = document.getElementById('parent-stencil-box');
  if (container) {
    container.addEventListener('click', () => {
      emit('click');
    });
  }
});

watch(
  () => fillColor.value,
  () => {
    if (previewBox.value) {
      previewBox.value.style.backgroundColor = fillColor.value;
    }
  }
);

defineExpose({
  rotateImage,
  flipImage
});
</script>

<style scoped>
.default-background {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
}
</style>
